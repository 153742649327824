import React from "react"
import { Link } from "gatsby"
//import { Row, Col, Container, Button } from "react-bootstrap"
import { Container } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import '../styles/SuperResponsiveTableStyle.css'

const pageTitle = "about"

const SecondPage = ({ pageContext, location }) => {

  const {
    breadcrumb: { crumbs },
  } = pageContext
  
  return (
    <Layout pageInfo={{ pageName: "about" }} crumbs={crumbs} location={location} crumbLabel={pageTitle}>
      <SEO title={pageTitle} />

      <Container fluid className="m-0 px-5">
        <h1 className="page-heading">About Us</h1>

        <h2 className="page-subheading">Our Mission</h2>

        <p>Our goal is to establish an enduring private sector desination that opens the path for you to visit the Moon, at a price you would expect to pay for the luxury-class European capital tour. </p>
          
        <p>We partner with like-minded organizations across the Earth to enable our vision.  <a href="https://www.moonsociety.org">The Moon Society</a> is the non-profit organization at the heart of our enterprise.</p>

        <h2 className="page-subheading">Our Vision</h2>

        <p>A thriving commerical lunar settlement available for anybody across the Earth to visit at an affordable. The settlement is funded privately and available to every Earth citizen who can afford the trip.</p>

        <h2 className="page-subheading">Our Projects</h2>
        <p>Our flagship project is <Link to="/projects">The Artemis Project</Link>, designed in 1994 and refined ever since. <br /> 
        It has been promoted since 1996 by the <a href="http://www.asi.org">Artemis Society International</a> and from 2000 by <a href="http://www.moonsociety.org">The Moon Society</a>.</p>

        <h2 className="page-subheading">Our Operations</h2>
        <p>Our official online store, <a href="https://lunartraders.com">Lunar Traders</a> is the retail outlet for Artemis Project merchandise and products.<br /> 

        Our publishing imprint, <a href="https://lunacitypress.org">Luna City Press</a> produces print and e-books that feature lunar topics.</p>



        <h2 className="page-subheading">Officers and Board Members</h2>

    <Table style={{ width: '600px' }}>
      <Thead>
        <Tr>
          <Th>Officer</Th>
          <Th>Title</Th>
          <Th>Contact</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>Michael Mealling</Td>
          <Td><b>President/CEO</b></Td>
          <Td><a href="mailto:tlrc-president@moonsociety.org">tlrc-president@moonsociety.org</a></Td>
        </Tr>
        <Tr>
          <Td>James L. Burk</Td>
          <Td><b>Vice President</b></Td>
          <Td><a href="mailto:tlrc-vice-president@moonsociety.org">tlrc-vice-president@moonsociety.org</a></Td>
        </Tr>
        <Tr>
          <Td>Dana Carson</Td>
          <Td><b>Treasurer</b></Td>
          <Td><a href="mailto:tlrc-treasurer@moonsociety.org">tlrc-treasurer@moonsociety.org</a></Td>
        </Tr>
        <Tr>
          <Td>Keith Garrett</Td>
          <Td><b>Secretary</b></Td>
          <Td><a href="mailto:tlrc-secretary@moonsociety.org">tlrc-secretary@moonsociety.org</a></Td>
        </Tr>
      </Tbody>
    </Table>

        <br />
        <b>Board of Directors</b><br />
        Philip Crume, <b>Chairman of the Board</b><br />
        Paul Banyai<br />
        James L. Burk<br />
        Dana Carson<br />
        Rosalie Dieteman<br />
        Keith Garrett<br />
        James Gholston<br />
        Michael Mealling<br />
        Ben Smith<br />

        <h2 className="page-subheading">Governing Documents</h2>

          <ul className="large-links">
            <li><Link to="/about/bylaws">Bylaws</Link></li>
            <li><Link to="/about/articles-of-incorporation">Articles of Incorporation</Link></li>
            <li><Link to="/about/resolution-merger-moonsoc-tlrc">Resolution for Merger of Moon Society &amp; The Lunar Resources Company</Link></li>
            <li><Link to="/about/corporate-policy-direction">2020 Company Policy Document</Link></li>
          </ul>

      </Container>
    </Layout>
  )
}

export default SecondPage
